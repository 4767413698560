import { sharedRef, useVSFContext } from '@vue-storefront/core';
import { Logger } from '../../utils';
import { useCart } from '@vsf-enterprise/commercetools';
import { computed, Ref } from '@nuxtjs/composition-api';
import { Cart } from '@vsf-enterprise/commercetools-types';
import { ERROR_RESULT_CODES } from '../../consts';
import useBuildDropinConfiguration from '../useBuildDropinConfiguration';
import { AdyenComponentData, VSFContext, GetPaymentMethodsResponse, PaymentWithFields, AdyenClientConfig, RemoveCardParams } from '../../types';
import {validateCustomAttributes} from '../../validator/validateCustomAttributes';

interface UseAdyenErrors {
  submitAdditionalPaymentDetails: Error,
  createContext: Error,
  payAndOrder: Error,
  removeCard: Error
};

const useAdyen = () => {
  const error = sharedRef<UseAdyenErrors>({
    submitAdditionalPaymentDetails: null,
    createContext: null,
    payAndOrder: null,
    removeCard: null
  }, 'useAdyen-error');
  const paymentObject = sharedRef<PaymentWithFields>(null, 'useAdyen-paymentObject');
  const loading = sharedRef(false, 'useAdyen-loading');

  const { $ct: { config: { locale } }, $adyen: { config: adyenConfig, api }} = useVSFContext() as VSFContext;
  const { cart, load } = useCart();
  const { buildDropinConfiguration } = useBuildDropinConfiguration();

  const createContext = async (): Promise<GetPaymentMethodsResponse> => {
    try {
      loading.value = true;
      error.value.createContext = null;

      await load();

      const response = await api.getPaymentMethodsRequest({
        cartId: cart.value.id,
        shopperLocale: locale,
        customerId: (cart.value as Cart).customerId
      });

      const paymentMethodsResponse = JSON.parse(response.custom.fields.getPaymentMethodsResponse) as GetPaymentMethodsResponse;
      const filteredPaymentMethodsResponse = _filterUnimplemented(adyenConfig, paymentMethodsResponse);

      setPaymentObject(response);
      loading.value = false;

      return filteredPaymentMethodsResponse;
    } catch (err) {
      Logger.error(err.message);
      error.value.createContext = _getError(err);
      loading.value = false;
    }
  };

  const payAndOrder = async (componentData: AdyenComponentData): Promise<void> => {
    try {
      loading.value = true;
      error.value.payAndOrder = null;

      const cartId = cart.value.id;
      const customerId = cart.value.customerId;
      const validation = validateCustomAttributes({cartId, customerId});

      if (!validation.isValid) {
        throw new Error('[Pay and Order]' + validation.errors.join(' '));
      }

      const newPaymentObject = await api.makePaymentRequest({
        paymentData: {
          id: paymentObject.value.id,
          version: paymentObject.value.version,
          amountPlanned: paymentObject.value.amountPlanned
        },
        componentData,
        cartId,
        customerId
      });

      setPaymentObject(newPaymentObject);
      _checkForErrorInResponse(newPaymentObject, error, 'makePaymentResponse', 'payAndOrder');
    } catch (err) {
      Logger.error(err.message);
      error.value.payAndOrder = _getError(err);
    } finally {
      loading.value = false;
    }
  };

  const submitAdditionalPaymentDetails = async (componentData: AdyenComponentData): Promise<void> => {
    try {
      loading.value = true;
      error.value.submitAdditionalPaymentDetails = null;

      const cartId = cart.value.id;
      const validation = validateCustomAttributes({ cartId });

      if (!validation.isValid) {
        throw new Error('[Submit additional payment details]' + validation.errors.join(' '));
      }

      const newPaymentObject = await api.submitAdditionalPaymentDetailsRequest({
        paymentData: {
          id: paymentObject.value.id,
          version: paymentObject.value.version
        },
        componentData,
        cartId
      });

      setPaymentObject(newPaymentObject);
      _checkForErrorInResponse(newPaymentObject, error, 'submitAdditionalPaymentDetailsResponse', 'submitAdditionalPaymentDetails');
    } catch (err) {
      Logger.error(err.message);
      error.value.submitAdditionalPaymentDetails = _getError(err);
    } finally {
      loading.value = false;
    }
  };

  const removeCard = async (params: RemoveCardParams): Promise<boolean> => {
    try {
      loading.value = true;
      error.value.removeCard = null;

      await api.removeCard(params);
      return true;
    } catch (err) {
      Logger.error(err.message);
      error.value.removeCard = _getError(err);
      return false;
    } finally {
      loading.value = false;
    }
  };

  const setPaymentObject = (newPaymentObject: PaymentWithFields) => paymentObject.value = newPaymentObject;

  return {
    error: computed<UseAdyenErrors>(() => error.value),
    loading: computed<boolean>(() => loading.value),
    paymentObject: computed<PaymentWithFields>(() => paymentObject.value),

    createContext,
    payAndOrder,
    buildDropinConfiguration,
    submitAdditionalPaymentDetails,
    removeCard,
    setPaymentObject
  }
};

export default useAdyen;

const _getError = (err) => err.response || err.data || err;

const _filterUnimplemented = (adyenConfig: AdyenClientConfig, paymentMethodsResponse: GetPaymentMethodsResponse): GetPaymentMethodsResponse => {
  return {
    ...paymentMethodsResponse,
    paymentMethods: paymentMethodsResponse.paymentMethods.filter(
      paymentMethod => adyenConfig.availablePaymentMethods.includes(paymentMethod.type)
    )
  }
};

const _checkForErrorInResponse = (newPaymentObject: PaymentWithFields, error: Ref<UseAdyenErrors>, fieldName: string, errorFieldName: string): void => {
  if (newPaymentObject?.custom?.fields?.[fieldName]) {
    const response = JSON.parse(newPaymentObject.custom.fields[fieldName]);
    if (ERROR_RESULT_CODES.includes(response.resultCode)) {
      error.value = {
        ...error.value,
        [errorFieldName]: response
      };
    }
  }
};
