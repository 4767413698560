import { Logger } from '../../utils';
import { Cart } from '@vsf-enterprise/commercetools-types';

const getTotalPriceFromCart = (cart: Cart): number => {
  const price = cart.taxedPrice?.totalGross?.centAmount;
  if (!price || isNaN(price)) {
    Logger.error('You did not collect all essential checkout data, at first save both shipping and billing details.');
    return;
  }
  return price;
};

export default getTotalPriceFromCart;
