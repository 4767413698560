import { Logger } from '../../../utils';
import { PaymentMethodConfigurationBuilderFnArg } from './types';

const paypalConfigurationBuilderFn = (data: PaymentMethodConfigurationBuilderFnArg): PaymentMethodConfigurationBuilderFnArg => {
  const {
    dropinConfig,
    reinitPayment,
    setErrorMessage,
    adyenConfig,
    currency,
    country,
    price
  } = data;

  if (!adyenConfig.methods.paypal?.merchantId || !adyenConfig.methods.paypal?.intent) {
    Logger.error('Required paypal configuration not provided.');
    return data;
  }

  const paypal = {
    merchantId: adyenConfig.methods.paypal.merchantId,
    configuration: {
      merchantId: adyenConfig.methods.paypal.merchantId,
      intent: adyenConfig.methods.paypal.intent
    },
    environment: adyenConfig.environment,
    countryCode: country,
    amount: {
      currency,
      value: price
    },
    intent: adyenConfig.methods.paypal.intent,
    // It can happen if users exitds pop-up when it's already loaded
    onCancel() {
      return reinitPayment();
    },
    // It can happen if user exits pop-up early or in case of real error
    onError() {
      setErrorMessage('Something went wrong. Please try again with a different than PayPal payment method.');
      return reinitPayment();
    }
  };

  return {
    ...data,
    dropinConfig: {
      ...dropinConfig,
      paypal
    }
  };
};

export default paypalConfigurationBuilderFn;
