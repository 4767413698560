import { PaymentMethodConfigurationBuilderFnArg } from './types';

const schemeConfigurationBuilderFn = (data: PaymentMethodConfigurationBuilderFnArg): PaymentMethodConfigurationBuilderFnArg => {
  const {
    adyenConfig,
    dropinConfig,
    recurringPaymentsEnabled
  } = data;

  const card = {
    hasHolderName: true,
    holderNameRequired: true,
    ...(adyenConfig.methods?.card || {}),
    enableStoreDetails: recurringPaymentsEnabled,
    showStoredPaymentMethods: recurringPaymentsEnabled
  };

  return {
    ...data,
    dropinConfig: {
      ...dropinConfig,
      card
    }
  };
};

export default schemeConfigurationBuilderFn;