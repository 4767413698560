import pmPaypal from './paypal';
import pmApplepay from './applepay';
import pmPaywithgoogle from './paywithgoogle';
import pmCard from './scheme';
import pmIdeal from './ideal';
import { PaymentMethodConfigurationBuilderFn, PaymentMethodConfigurationBuilderFnArg } from './types';

const paymentMethodConfigurationBuilderFns: Record<string, PaymentMethodConfigurationBuilderFn> = {
  paypal: pmPaypal,
  applepay: pmApplepay,
  paywithgoogle: pmPaywithgoogle,
  scheme: pmCard,
  ideal: pmIdeal
};

export default (config: PaymentMethodConfigurationBuilderFnArg, paymentMethod: string): PaymentMethodConfigurationBuilderFnArg => {
  return paymentMethodConfigurationBuilderFns[paymentMethod]
    ? paymentMethodConfigurationBuilderFns[paymentMethod](config)
    : config;
}
