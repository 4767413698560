import { PaymentMethodConfigurationBuilderFnArg } from './types';

const paywithgoogleConfigurationBuilderFn = (data: PaymentMethodConfigurationBuilderFnArg): PaymentMethodConfigurationBuilderFnArg => {
  const {
    dropinConfig,
    adyenConfig,
    currency,
    country,
    price
  } = data;

  const paywithgoogle = {
    ...adyenConfig.methods?.paywithgoogle,
    environment: adyenConfig.environment,
    amount: {
      value: price,
      currency
    },
    countryCode: country
  };

  return {
    ...data,
    dropinConfig: {
      ...dropinConfig,
      paywithgoogle
    }
  };
};

export default paywithgoogleConfigurationBuilderFn;
