import type { customAttributes } from '../types';

/**
 * Validates the custom attributes for the commercetools payment request.
 */
export const validateCustomAttributes = (attributes: customAttributes) => {
  let isValid = true;
  const errors: string[] = [];

  if (!attributes.cartId) {
    isValid = false;
    errors.push('Cart ID is required.');
  }

  return {
    isValid,
    errors
  };
};
