import type { PaymentMethodConfigurationBuilderFnArg } from './types';

const idealConfigurationBuilderFn = (data: PaymentMethodConfigurationBuilderFnArg): PaymentMethodConfigurationBuilderFnArg => {
  const {
    dropinConfig,
    adyenConfig,
  } = data;

  const ideal = adyenConfig.methods?.ideal 

  return {
    ...data,
    dropinConfig: {
      ...dropinConfig,
      ...(ideal ? { ideal } : {})
    }
  };
};

export default idealConfigurationBuilderFn;
