import { useVSFContext } from '@vue-storefront/core';
import { useCart, useUser } from '@vsf-enterprise/commercetools';
import { Cart } from '@vsf-enterprise/commercetools-types';
import { VSFContext, GetPaymentMethodsResponse, SetErrorMessageFn, ReinitPaymentFn, OnChangeFn, OnSubmitFn, OnAdditionalDetailsFn, OnErrorFn } from '../../types';
import getTotalPriceFromCart from './getTotalPriceFromCart';
import buildPaymentMethodsConfiguration from './buildPaymentMethodsConfiguration';
import buildPaymentMethodsResponse from './buildPaymentMethodsResponse';

function useBuildDropinConfiguration() {
  const { $ct: { config: { locale, currency, country } }, $adyen: { config: adyenConfig }} = useVSFContext() as VSFContext;
  const { cart } = useCart();
  const { isAuthenticated } = useUser();
  const $t = str => str; // TODO

  return {
    buildDropinConfiguration: ({
      paymentMethodsResponse,
      reinitPayment,
      setErrorMessage,
      onChange = null,
      onSubmit = null,
      onAdditionalDetails = null,
      onError = null
    }: {
      paymentMethodsResponse: GetPaymentMethodsResponse,
      reinitPayment: ReinitPaymentFn,
      setErrorMessage: SetErrorMessageFn,
      onChange: OnChangeFn,
      onSubmit: OnSubmitFn,
      onAdditionalDetails: OnAdditionalDetailsFn,
      onError: OnErrorFn
    }) => {

      const totalGrossPrice = getTotalPriceFromCart(cart.value as Cart);
      const recurringPaymentsEnabled = adyenConfig.recurringPayments && isAuthenticated.value;

      return {
        paymentMethodsConfiguration: buildPaymentMethodsConfiguration({
          price: totalGrossPrice,
          reinitPayment,
          setErrorMessage,
          recurringPaymentsEnabled,
          adyenConfig,
          currency,
          country,
          locale,
          $t
        }),
        paymentMethodsResponse: buildPaymentMethodsResponse(paymentMethodsResponse, recurringPaymentsEnabled),
        translations: {
          'en-US': {
            payButton: 'Order and Pay'
          }
        },
        clientKey: adyenConfig.clientKey,
        environment: adyenConfig.environment,
        amount: {
          // Optional. Used to display the amount in the Pay Button.
          value: totalGrossPrice,
          currency,
        },
        showPayButton: true,
        locale,
        onChange,
        onSubmit,
        onAdditionalDetails,
        onError
      };
    }
  };
}

export default useBuildDropinConfiguration;
