export const API_ENDPOINT_AFTER_AUTH_REDIRECT_BACK = 'afterAuthRedirectBack';
export const KLARNA_METHODS = ['klarna', 'klarna_account', 'klarna_paynow'];
export const MALFORMED_PRICE_ERROR = 'Malformed price';
export const TRANSACTION_CANCELLED_ERROR = 'Transaction cancelled';
export const TRANSACTION_FAILED_ERROR = 'Transaction failed';
export const CREATING_ORDER_FAILED_ERROR = 'Creating order failed';
export const UNPROCESSABLE_ENTITY_ERROR = 'Unprocessable entity';
export const PMS_REQUIRING_ADDITIONAL_FIELDS = [
  ...KLARNA_METHODS,
  'zip',
  'afterpaytouch',
  'facilypay_3x',
  'facilypay_4x',
  'facilypay_6x',
  'facilypay_10x',
  'facilypay_12x',
  'affirm',
  'ratepay'
];
export const ERROR_RESULT_CODES = ['Refused', 'Error', 'Cancelled'];
export const DEFAULT_USER_SESSION_COOKIE_NAME = 'vsf-commercetools-token';
