import { GetPaymentMethodsResponse } from '../../types';

const buildPaymentMethodsResponse = (paymentMethodsResponse: GetPaymentMethodsResponse, recurringPaymentsEnabled: boolean): GetPaymentMethodsResponse => {
  return recurringPaymentsEnabled
    ? paymentMethodsResponse
    : {
      paymentMethods: paymentMethodsResponse.paymentMethods
    };
};

export default buildPaymentMethodsResponse;
