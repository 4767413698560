import { PaymentMethodConfigurationBuilderFnArg } from './types';

const applepayConfigurationBuilderFn = (data: PaymentMethodConfigurationBuilderFnArg): PaymentMethodConfigurationBuilderFnArg => {
  const {
    dropinConfig,
    adyenConfig,
    currency,
    country,
    price
  } = data;

  const applepay = {
    ...(adyenConfig.methods?.applepay || {}),
    amount: {
      currency,
      value: price
    },
    countryCode: country
  };

  return {
    ...data,
    dropinConfig: {
      ...dropinConfig,
      applepay
    }
  };
};

export default applepayConfigurationBuilderFn;
