import paymentMethodConfigurationBuilderFns from './paymentMethods';
import { AdyenClientConfig, SetErrorMessageFn, ReinitPaymentFn } from '../../types';

const buildPaymentMethodsConfiguration = (params: {
  reinitPayment: ReinitPaymentFn,
  setErrorMessage: SetErrorMessageFn,
  recurringPaymentsEnabled: boolean,
  adyenConfig: AdyenClientConfig,
  currency: string,
  country: string,
  price: number,
  locale: string,
  $t: (text: string) => string
}) => {
  return params.adyenConfig.availablePaymentMethods.reduce(paymentMethodConfigurationBuilderFns, {
    reinitPayment: params.reinitPayment,
    setErrorMessage: params.setErrorMessage,
    dropinConfig: {
      name: params.$t('Credit or debit card'),
      amount: {
        value: params.price,
        currency: params.currency
      },
    },
    ...params
  }).dropinConfig;
};

export default buildPaymentMethodsConfiguration;
