/* istanbul ignore file */
import consola from 'consola';

const consolaInstance = consola.create({
  level: Number(process.env.ADYEN_LOGS_VERBOSITY) || 3
});

const logMessageFactory = (level: 'error' | 'warn' | 'info' | 'debug') => (
  message: string,
  calleeName?: string
) => {
  consolaInstance[level](`[VSF][AdyenCT]${calleeName ? `[${calleeName}]` : ''}: ${message}`);
};

export const Logger = {
  error: logMessageFactory('error'),
  warn: logMessageFactory('warn'),
  info: logMessageFactory('info'),
  debug: logMessageFactory('debug')
};
